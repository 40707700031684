<template>
  <div>
    <!-- Page Title
		============================================= -->
    <section id="page-title">
      <div class="container clearfix">
        <h1>Blog</h1>
        <span>Our Latest News</span>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/">Home</router-link>
          </li>
          <li
            class="breadcrumb-item active"
            aria-current="page">
            Blog
          </li>
        </ol>
      </div>
    </section>
    <!-- #page-title end -->

    <!-- Content
		============================================= -->
    <section id="content">
      <div class="content-wrap">
        <div class="container clearfix">
          <!-- Posts
					============================================= -->
          <div
            id="posts"
            class="post-grid row grid-container gutter-50 clearfix"
            data-layout="fitRows">
            <!-- Add new posts under this line -->

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/maintaining-company-culture-virtual-workforce"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/86eqcc5k6-thumbnail.png"
                      style="max-height: 375px; max-width: 400px; margin: auto"
                      alt="Multisourcing"
                      class="nostyle pb-4" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/maintaining-company-culture-virtual-workforce">
                      Maintaining Company Culture with a Virtual Workforce
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      12th December 2024
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/cres.png"
                        alt="cole"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Creselda Jane A. Ebba
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Imagine this: You have a team of experts spread across the globe, each bringing unique skills and perspectives, yet working in perfect harmony as if they were sitting in the same office. As businesses increasingly turn to remote work and outsourcing, this scenario is becoming the norm, but it brings with it one major challenge—how do you maintain a strong, unified company culture when your team is scattered across different time zones and locations?
                  </p>
                  <router-link
                    to="/blog/maintaining-company-culture-virtual-workforce"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/mastering-project-management-offshore-distributed-teams"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/86eqc6c28-thumbnail.png"
                      style="max-height: 375px; max-width: 400px; margin: auto"
                      alt="Multisourcing"
                      class="nostyle pb-4" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/mastering-project-management-offshore-distributed-teams">
                      Mastering Project Management for Offshore and Distributed
                      Teams
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      5th December 2024
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/michelle.png"
                        alt="cole"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Michelle Zamora
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Remote work has quickly shifted from a trend to a standard
                    way of operating for many businesses.
                  </p>
                  <p>
                    As more teams are spread across multiple cities, countries,
                    and even continents, the need for effective project
                    management has never been greater. But leading a distributed
                    team isn't just about setting up Zoom calls and sending
                    emails—it’s about creating a system that keeps everyone
                    aligned, productive, and motivated despite the physical
                    distance.
                  </p>
                  <router-link
                    to="/blog/mastering-project-management-offshore-distributed-teams"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/essential-performance-metrics-outsourcing-success"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/86eqcc5m9-thumbnail.png"
                      style="max-height: 375px; max-width: 400px; margin: auto"
                      alt="Multisourcing"
                      class="nostyle pb-4" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/essential-performance-metrics-outsourcing-success">
                      Essential Performance Metrics for Outsourcing Success
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      28th November 2024
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/michelle.png"
                        alt="cole"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Michelle Zamora
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Outsourcing can feel like handing over the reins—exciting
                    but nerve-wracking. Will your external team truly deliver?
                    Can you keep things running smoothly without constant
                    oversight? Here's the good news: with the proper performance
                    metrics, outsourcing can be seamless and become a
                    game-changer for your business growth.
                  </p>
                  <router-link
                    to="/blog/essential-performance-metrics-outsourcing-success"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/safeguard-data-outsourcing-offshore-teams"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/86eqcc5mg-thumbnail.png"
                      style="max-height: 375px; max-width: 400px; margin: auto"
                      alt="Multisourcing"
                      class="nostyle pb-4" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/safeguard-data-outsourcing-offshore-teams">
                      How to Safeguard Your Data When Outsourcing to Offshore
                      Teams
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      21st November 2024
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/cole.jpg"
                        alt="cole"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Cole Abat
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Offshore outsourcing can be a game-changer for your
                    business—but here's the million-dollar question: Is your
                    data truly safe? When collaborating with remote teams
                    worldwide, your sensitive information is exposed to more
                    risks than ever. With cyberattacks on the rise, protecting
                    your data isn't just an IT issue; it's a business
                    imperative.
                  </p>
                  <router-link
                    to="/blog/safeguard-data-outsourcing-offshore-teams"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/long-term-success-trusting-outsourcing-partners"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/longtermthumbnail.png"
                      style="max-height: 375px; max-width: 400px; margin: auto"
                      alt="Multisourcing"
                      class="nostyle pb-4" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/long-term-success-trusting-outsourcing-partners">
                      Long-Term Success in Business: Trusting Your Outsourcing
                      Partners
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      12th November 2024
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/krystel.jpg"
                        alt="cole"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Krystel Moore
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Outsourcing has become a strategic cornerstone for companies
                    aiming to grow, scale, and access specialized expertise
                    without the overhead. However, the key to achieving
                    long-term success with outsourcing lies in choosing the
                    right partner and building a foundation of trust and
                    collaboration that stands the test of time. Establishing a
                    robust and reliable relationship with your outsourcing
                    partners enables short-term wins and sustainable growth.
                  </p>
                  <router-link
                    to="/blog/long-term-success-trusting-outsourcing-partners"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/scaling-your-business-with-outsourcing-strategic-guide"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/scalingbusinessoutsourcingthumbnail.png"
                      style="max-height: 375px; max-width: 400px; margin: auto"
                      alt="Multisourcing"
                      class="nostyle pb-4" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/scaling-your-business-with-outsourcing-strategic-guide">
                      Scaling Your Business with Outsourcing: A Strategic Guide
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      4th November 2024
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/rob.jpg"
                        alt="cole"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Rob Astudillo
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    When it comes to scaling your business, it’s not just about
                    getting bigger—it’s about working smarter.
                  </p>
                  <p>
                    Growth is exciting, but it also requires careful planning
                    and the right strategy. That’s where outsourcing comes in.
                    By outsourcing non-core tasks, you can focus on what really
                    drives your business forward—innovation, growth, and staying
                    competitive.
                  </p>
                  <router-link
                    to="/blog/scaling-your-business-with-outsourcing-strategic-guide"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/virtual-team-best-practices-seamless-collaboration"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/virtualteampracticesthumbnail.png"
                      style="max-height: 375px; max-width: 400px; margin: auto"
                      alt="Multisourcing"
                      class="nostyle pb-4" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/virtual-team-best-practices-seamless-collaboration">
                      5 Virtual Team Best Practices for Seamless Collaboration
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      28th October 2024
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/cole.jpg"
                        alt="cole"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Cole Abat
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Picture this: your virtual teams are working seamlessly
                    across time zones, collaborating without missing a beat, and
                    driving exceptional results—all while you focus on scaling
                    your business. It’s not a distant dream.
                    <strong>
                      It’s the power of strategic virtual team management.
                    </strong>
                  </p>
                  <router-link
                    to="/blog/virtual-team-best-practices-seamless-collaboration"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/strong-outsourcing-relationships-open-communication"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/secrettosstrongthumbnail.png"
                      style="max-height: 375px; max-width: 400px; margin: auto"
                      alt="Multisourcing"
                      class="nostyle pb-4" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/strong-outsourcing-relationships-open-communication">
                      The Secret to Strong Outsourcing Relationships: Open
                      Communication
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      23rd October 2024
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/michelle.png"
                        alt="michelle"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Michelle Zamora
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Imagine having a team that perfectly syncs with your
                    in-house staff, helping you scale and grow your business
                    effortlessly. It’s not just a dream—it’s the reality of
                    strategic outsourcing. What makes this work so well? Open
                    communication.
                  </p>
                  <router-link
                    to="/blog/strong-outsourcing-relationships-open-communication"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/onboarding-outsourced-teams-what-to-expect-and-how-to-prepare"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/whattoexepect3.png"
                      style="max-height: 375px; max-width: 400px; margin: auto"
                      alt="Multisourcing"
                      class="nostyle pb-4" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/onboarding-outsourced-teams-what-to-expect-and-how-to-prepare">
                      Onboarding Outsourced Teams: What to Expect and How to
                      Prepare
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      11th October 2024
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/cres.png"
                        alt="Rob"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Creselda Jane A. Ebba
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Imagine having a team of experts at your fingertips, working
                    in perfect harmony with your in-house staff, all while you
                    focus on growing your business. This isn't a dream—
                    <strong>it's the power of strategic outsourcing.</strong>
                  </p>
                  <router-link
                    to="/blog/onboarding-outsourced-teams-what-to-expect-and-how-to-prepare"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/maximizing-efficiency-with-ai-and-outsourcing"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/ai.jpg"
                      alt="Multisourcing"
                      style="max-height: 375px; max-width: 400px; margin: auto"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/maximizing-efficiency-with-ai-and-outsourcing">
                      Maximizing Efficiency with AI and Outsourcing
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      25th September 2024
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/rob.jpg"
                        alt="Rob"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Rob Astudillo
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Navigating the complex business growth landscape can be
                    challenging, especially for tech companies experiencing
                    rapid expansion. Finding efficient and cost-effective
                    solutions is crucial to sustaining this growth. Enter
                    Artificial Intelligence (AI) and outsourcing—two powerful
                    tools that can drive sustainable business growth while
                    maximizing efficiency and scalability. Let's explore how
                    these strategies can transform your business operations.
                  </p>
                  <router-link
                    to="/blog/maximizing-efficiency-with-ai-and-outsourcing"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/unlocking-efficiency-the-power-of-outsourcing-complex-tasks"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/unlocking.png"
                      alt="Multisourcing"
                      style="max-height: 375px; max-width: 400px; margin: auto"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/unlocking-efficiency-the-power-of-outsourcing-complex-tasks">
                      Unlocking Efficiency: The Power of Outsourcing Complex
                      Tasks
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      16th September 2024
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/cole.jpg"
                        alt="Rob"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Cole Abat
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Outsourcing has evolved far beyond handling simple,
                    repetitive tasks. Today, businesses are discovering that
                    they can outsource even their most complex processes,
                    freeing up internal teams to focus on innovation and growth.
                    Despite concerns about losing control over these intricate
                    operations, companies that partner with specialized
                    outsourcing providers often achieve greater efficiency and
                    cost savings.
                  </p>
                  <router-link
                    to="/blog/unlocking-efficiency-the-power-of-outsourcing-complex-tasks"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/bridging-skills-gap-virtual-assistants"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/bridginggap.png"
                      alt="Multisourcing"
                      style="max-height: 375px; max-width: 400px; margin: auto"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/bridging-skills-gap-virtual-assistants">
                      Bridging the Skills Gap: How Virtual Assistants Offer
                      Vital Business Support
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      16th September 2024
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/cole.jpg"
                        alt="Rob"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Cole Abat
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    As many business owners know, finding the right talent can
                    be a constant challenge, especially when specialized skills
                    are in short supply. It's not just about filling
                    positions—it's about finding the right fit to drive your
                    business forward. With talent shortages becoming a
                    persistent issue across various industries, many companies
                    are exploring new strategies to bridge the gap. One approach
                    that's gaining traction is outsourcing, particularly by
                    leveraging virtual assistants (VAs). These skilled
                    professionals offer a flexible and cost-effective solution
                    to accessing the expertise that businesses need to stay
                    competitive.
                  </p>
                  <router-link
                    to="/blog/bridging-skills-gap-virtual-assistants"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/outsourcing-strategies-economic-downturn"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/economicdownturns.png"
                      alt="Multisourcing"
                      style="max-height: 375px; max-width: 400px; margin: auto"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/outsourcing-strategies-economic-downturn">
                      Outsourcing Strategies During an Economic Downturn
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      10th September 2024
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/rob.jpg"
                        alt="Rob"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Rob Astudillo
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    An economic downturn can put significant pressure on
                    businesses, forcing them to reevaluate their strategies and
                    find ways to maintain growth and profitability. During these
                    challenging times, shrinking budgets and increased
                    competition require innovative approaches to streamline
                    operations and reduce costs. One effective solution that
                    many businesses are turning to is outsourcing. By leveraging
                    virtual assistants (VAs) for non-core tasks, companies can
                    not only survive but thrive during tough economic
                    conditions.
                  </p>
                  <router-link
                    to="/blog/outsourcing-strategies-economic-downturn"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/virtual-assistants-pet-concierge-businesses"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/PetConcierge.png"
                      alt="BPO"
                      style="max-height: 375px; max-width: 400px; margin: auto"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/virtual-assistants-pet-concierge-businesses">
                      Virtual Assistants: The Key to Thriving Pet Concierge
                      Businesses
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      26th August 2024
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/krystel.jpg"
                        alt="Image"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Krystel Moore
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Managing a pet care business is a rewarding but challenging
                    endeavor. The endless to-do lists—from handling client
                    inquiries to managing appointments and maintaining a social
                    media presence—can quickly become overwhelming. For many pet
                    professionals, the solution lies in outsourcing,
                    particularly through virtual assistants (VAs). By delegating
                    administrative and customer service tasks to VAs, pet
                    concierge businesses can focus on what truly matters:
                    providing exceptional care to pets.
                  </p>
                  <router-link
                    to="/blog/virtual-assistants-pet-concierge-businesses"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/outsourcing-sustainable-growth-flexibility"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/SustainableGrowth.png"
                      alt="BPO"
                      style="max-height: 375px; max-width: 400px; margin: auto"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/outsourcing-sustainable-growth-flexibility">
                      The Power of Flexibility: How Outsourcing Drives
                      Sustainable Growth
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      26th August 2024
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/krystel.jpg"
                        alt="Image"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Krystel Moore
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Scaling too quickly can put businesses in a tough spot.
                    We’ve seen it happen—companies expanding rapidly, hiring new
                    teams, and pushing for growth at all costs, only to be hit
                    with layoffs and financial challenges when market conditions
                    shift. It’s clear that growth is important, but doing it
                    sustainably is even more critical. Instead of getting caught
                    in the cycle of overexpansion, businesses are discovering
                    that outsourcing—especially with offshore virtual
                    teams—offers a smarter, more flexible path to growth.
                  </p>
                  <router-link
                    to="/blog/outsourcing-sustainable-growth-flexibility"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/evolution-of-outsourcing-in-hotels-and-hospitality"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/outsourcinghotelimage.webp"
                      alt="BPO"
                      style="max-height: 375px; max-width: 400px; margin: auto"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/evolution-of-outsourcing-in-hotels-and-hospitality">
                      The Evolution of Outsourcing in Hotels and Hospitality:
                      Navigating Market Trends
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      22nd August 2024
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/rob.jpg"
                        alt="Image"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Rob Astudillo
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    In the ever-changing landscape of the hospitality industry,
                    staying ahead of the curve is crucial. With the latest
                    developments in leveraging outsourced resources, hotels and
                    hospitality companies are finding innovative ways to drive
                    success in 2023 and 2024. From digital transformation to
                    sustainability initiatives, the role of outsourcing has
                    evolved significantly to meet the growing demands of the
                    market.
                  </p>
                  <router-link
                    to="/blog/evolution-of-outsourcing-in-hotels-and-hospitality"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <!-- Done -->
            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/voted-most-trusted-bpo-company-and-now-soc-ii-compliant"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/mosttrustedbpo.png"
                      alt="Multisourcing"
                      style="max-height: 375px; margin: auto; max-width: 500px"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/voted-most-trusted-bpo-company-and-now-soc-ii-compliant">
                      eFlexervices: Voted Most Trusted BPO Company by APAC
                      Insider and Now SOC II Compliant
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      31st July 2024
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/rob.jpg"
                        alt="Rob"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Rob Astudillo
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    eFlexervices has recently been recognized by APAC Insider as
                    the Most Trusted BPO Company for 2024 in the Philippines.
                    This accolade underscores the company’s commitment to
                    delivering exemplary Business Process Outsourcing (BPO)
                    services. Additionally, eFlexervices has achieved SOC II
                    compliance, further solidifying its position as a leader in
                    data security and operational integrity. These milestones
                    highlight the company's dedication to innovation, client
                    satisfaction, and quality service.
                  </p>
                  <router-link
                    to="/blog/voted-most-trusted-bpo-company-and-now-soc-ii-compliant"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/why-outsourcing-is-the-secret-weapon-for-small-business-owners"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/vablog.webp"
                      alt="Multisourcing"
                      style="max-height: 375px; margin: auto; max-width: 500px"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/why-outsourcing-is-the-secret-weapon-for-small-business-owners">
                      Why Outsourcing is the Secret Weapon for Small Business
                      Owners
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      30th July 2024
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/cole.jpg"
                        alt="Rob"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Cole Abat
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Many small business owners and SMBs often find themselves
                    overwhelmed with day-to-day tasks, struggling to find time
                    for innovation and lacking reliable administrative support.
                    However, there is a secret weapon that can help alleviate
                    these challenges: outsourcing. Contrary to popular belief,
                    outsourcing is not just for large corporations with
                    extensive resources. Small businesses can also leverage
                    outsourcing to reduce overhead costs, gain access to
                    specialized skills, and enhance operational efficiency.
                  </p>
                  <router-link
                    to="/blog/why-outsourcing-is-the-secret-weapon-for-small-business-owners"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <!-- Done -->
            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/the-new-era-of-outsourcing-in-finance-how-lending-companies-benefit"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/loanprocessor.webp"
                      alt="Multisourcing"
                      style="max-height: 375px; margin: auto; max-width: 500px"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/the-new-era-of-outsourcing-in-finance-how-lending-companies-benefit">
                      The New Era of Outsourcing in Finance: How Lending
                      Companies Benefit
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      30th July 2024
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/cole.jpg"
                        alt="Rob"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Cole Abat
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    In today's fast-paced lending landscape, companies are
                    constantly seeking innovative solutions to streamline their
                    operations, reduce costs, and drive growth. One such
                    solution that has gained significant traction in recent
                    years is customized outsourcing. By leveraging the expertise
                    of offshore teams, lending companies can revolutionize their
                    operations and focus on core activities to achieve long-term
                    success.
                  </p>
                  <router-link
                    to="/blog/the-new-era-of-outsourcing-in-finance-how-lending-companies-benefit"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/transforming-funeral-home-operations-through-outsourcing"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/funeralblog.webp"
                      alt="Multisourcing"
                      style="max-height: 375px; margin: auto; max-width: 500px"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/transforming-funeral-home-operations-through-outsourcing">
                      Outsourcing: The Game-Changer for Funeral Home Operations
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      30th July 2024
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/rob.jpg"
                        alt="Rob"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Rob Astudillo
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    In the fast-paced and emotionally demanding environment of
                    funeral homes, managing various operational tasks can be
                    overwhelming. With the primary focus on serving at-need
                    families, essential business functions often take a back
                    seat. This is where outsourcing can play a pivotal role in
                    enhancing efficiency and driving growth for funeral homes.
                  </p>
                  <router-link
                    to="/blog/transforming-funeral-home-operations-through-outsourcing"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/secure-efficient-outsourcing-technology"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/efficientoutsourcing.jpg"
                      alt="Multisourcing"
                      style="max-height: 375px; margin: auto; height: 500px"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/secure-efficient-outsourcing-technology">
                      STAY SECURE, STAY AHEAD: HOW TECHNOLOGY ENABLES SECURE AND
                      EFFICIENT OUTSOURCING/OFFSHORING
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      9th March 2024
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/rob.jpg"
                        alt="Rob"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Rob Astudillo
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    In today's business world, the challenge of finding top
                    talent amid rising inflation has made outsourcing and
                    offshoring vital strategies for growth. However, security
                    concerns often put a pause on these ambitions. It's time to
                    let go of those fears, thanks to the technological
                    advancements that have made remote collaboration not just
                    possible but incredibly secure.
                  </p>
                  <router-link
                    to="/blog/secure-efficient-outsourcing-technology"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/enhancing-logistics-through-strategic-outsourcing"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/StrategicOutsourcing.jpg"
                      alt="Multisourcing"
                      style="max-height: 375px; margin: auto; height: 500px"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/enhancing-logistics-through-strategic-outsourcing">
                      Enhancing Logistics Through Strategic Outsourcing
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      4th October 2023
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/krystel.jpg"
                        alt="Rob"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Krystel Moore
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Timely delivery and efficient processes are paramount in
                    logistics. As technology advances, the trend of outsourcing
                    within this sector is gaining momentum. This piece sheds
                    light on the advantages of logistics outsourcing and its
                    influence on businesses' competitiveness.
                  </p>
                  <router-link
                    to="/blog/enhancing-logistics-through-strategic-outsourcing"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/customized-outsourcing-advantages"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/solutions.jpg"
                      alt="Multisourcing"
                      style="
                        max-height: 375px;
                        max-width: 400px;
                        margin: auto;
                        height: 500px;
                      "
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link to="/blog/customized-outsourcing-advantages">
                      Customized Outsourcing Solutions: Unveiling Advantages and
                      Opportunities
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      28th September 2023
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/cole.jpg"
                        alt="Rob"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Cole Abat
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    In today's business world, outsourcing is a key strategy for
                    improving efficiency and reducing costs. But not all
                    outsourcing methods are the same. Choosing customized
                    solutions over generic ones can greatly enhance business
                    operations.
                  </p>
                  <router-link
                    to="/blog/customized-outsourcing-advantages"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/virtual-assistants-for-eCommerce-business"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/ecommerceva.jpg"
                      alt="Multisourcing"
                      style="max-height: 375px; margin: auto; height: 500px"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/virtual-assistants-for-eCommerce-business">
                      Leverage Virtual Assistants for Your eCommerce Business
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      24th December 2022
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/rob.jpg"
                        alt="Rob"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Rob Astudillo
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Are you an online entrepreneur interested in using a virtual
                    assistant to help you with your online store's operation?
                    What does an eCommerce VA do? We dive into how you can
                    leverage VAs who specialize in supporting eCommerce
                    businesses.
                  </p>
                  <router-link
                    to="/blog/virtual-assistants-for-eCommerce-business"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/Increase-Customer-Engagement"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/customerengagement.jpg"
                      alt="Multisourcing"
                      style="max-height: 375px; margin: auto; height: 500px"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link to="/blog/Increase-Customer-Engagement">
                      Leveraging Video Chat to Increase Customer Engagement
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      15th December 2022
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/rob.jpg"
                        alt="Rob"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Rob Astudillo
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Businesses can achieve clear customer communications with
                    video chat as another tool. This article will cover the
                    benefits of using video chat for customer service and how to
                    leverage video for business success.
                  </p>
                  <router-link
                    to="/blog/Increase-Customer-Engagement"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/Strategic-Recruitment-to-Attract-Top-Talent-for-Business-Success"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/recruitment.jpg"
                      alt="Multisourcing"
                      style="
                        max-height: 375px;
                        margin: auto;
                        height: 500px;
                        width: 500px;
                      "
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/Strategic-Recruitment-to-Attract-Top-Talent-for-Business-Success">
                      Recruit and Attract Top Talent for Business Success
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      27th October 2022
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/cole.jpg"
                        alt="Cole"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Cole Abat
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    An effective recruitment strategy can accelerate your
                    company's growth and allow your team to find top talent and
                    more engaged employees. We put together some tips we have
                    learned along the way that have helped eFlex and our
                    partners find the right team members to help us continue to
                    succeed and grow.
                  </p>
                  <router-link
                    to="/blog/Strategic-Recruitment-to-Attract-Top-Talent-for-Business-Success"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/Strategic-Benefits-of-Multisourcing"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/multisourcing.jpg"
                      alt="Multisourcing"
                      style="
                        max-height: 375px;
                        margin: auto;
                        height: 375px;
                        width: 375px;
                      "
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link to="/blog/Strategic-Benefits-of-Multisourcing">
                      Already Outsourcing? What About Multisourcing?
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      12th October 2022
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/rob.jpg"
                        alt="Rob"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Rob Astudillo
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    This article discusses multisourcing, a strategy that
                    businesses use when leveraging multiple outsourcing
                    providers. We will cover some of the pros and cons of
                    multisourcing to help you determine if this is something you
                    can explore for your own business.
                  </p>
                  <router-link
                    to="/blog/Strategic-Benefits-of-Multisourcing"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/highly-skilled-talent-in-the-philippines"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/phtalent.jpg"
                      alt="PHTalents"
                      style="
                        max-height: 375px;
                        margin: auto;
                        height: 375px;
                        width: 375px;
                      "
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/highly-skilled-talent-in-the-philippines">
                      Highly Skilled Talent In The Philippines
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      15th September 2022
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/rob.jpg"
                        alt="Rob"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Rob Astudillo
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    This article goes in-depth on why businesses are entrusting
                    key roles and functions to outsourced Filipino professionals
                    from finance and accounting, data science and analytics, web
                    development and design, digital marketing, search engine
                    optimization (SEO), and advanced technical support.
                  </p>
                  <router-link
                    to="/blog/highly-skilled-talent-in-the-philippines"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/google-analytics-4-vs-universal-analytics-which-is-better"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/ga.png"
                      alt="StigmaMyths"
                      style="
                        max-height: 375px;
                        margin: auto;
                        height: 375px;
                        width: 375px;
                      "
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/google-analytics-4-vs-universal-analytics-which-is-better">
                      Google Analytics UA vs GA4: Which is Better?
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      1st September 2022
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/phil.jpg"
                        alt="Phil"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Phil Indi Balanza
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Google announced that Universal Analytics (UA) will no
                    longer collect data after July 1, 2023. Since this switch is
                    inevitable, we rounded up the main differences between UA
                    and GA4 to help you navigate this critical change.
                  </p>
                  <router-link
                    to="/blog/google-analytics-4-vs-universal-analytics-which-is-better"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/debunking-stigma-around-outsourced-sales"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/stigma.jpg"
                      alt="StigmaMyths"
                      style="max-height: 375px; margin: auto; height: 375px"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/debunking-stigma-around-outsourced-sales">
                      Debunking The Stigma Around Outsourced Sales Teams
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      25th August 2022
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/krystel.jpg"
                        alt="Image"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Krystel Moore
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Are you considering leveraging an offshore team to help
                    drive your sales pipeline but are on the fence because
                    you've heard some horror stories? Then, check out this blog
                    post, where we dive into common misconceptions about sales
                    outsourcing and provide more information to help you make
                    the best decision for your business.
                  </p>
                  <router-link
                    to="/blog/debunking-stigma-around-outsourced-sales"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/scheduling-extended-coverage"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/scheduling.jpg"
                      alt="Scheduling"
                      style="max-height: 375px; margin: auto; height: 375px"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link to="/blog/scheduling-extended-coverage">
                      Scheduling Extended Coverage
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      3rd August 2022
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/nate.jpg"
                        alt="Image"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Nate Jularbal
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    When companies need extended coverage, one of the most vital
                    things to consider is scheduling their employees' work
                    shifts. Getting it right is essential to increase customer
                    satisfaction, control labor costs, and keep customers happy.
                    However, if you get it wrong, then your business can suffer.
                    This article discusses the options to consider when creating
                    a sustainable work shift schedule for your employees.
                  </p>
                  <router-link
                    to="/blog/scheduling-extended-coverage"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/service-level-agreements-for-outsourcing-services"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/SLA.jpg"
                      alt="HouseBlending"
                      style="max-height: 375px; margin: auto; height: 375px"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/service-level-agreements-for-outsourcing-services">
                      Service Level Agreements with Outsourcing Providers
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      3rd August 2022
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/rob.jpg"
                        alt="Image"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Rob Astudillo
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    There are differences between SLA metrics and KPIs that
                    outsourcing service providers should know about. This
                    article explains the importance of having them both, and
                    what valuable SLA metrics organizations should consider as
                    part of their contracts.
                  </p>
                  <router-link
                    to="/blog/service-level-agreements-for-outsourcing-services"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/cost-savings-when-hiring-a-va"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/vacostsave.jpg"
                      alt="HouseBlending"
                      style="max-height: 375px; margin: auto; height: 375px"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link to="/blog/cost-savings-when-hiring-a-va">
                      What are the cost savings when hiring a VA?
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      27th July 2022
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/krystel.jpg"
                        alt="Image"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Krystel Moore
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    CEOs and business owners constantly look for cost-effective
                    ways to offload administrative work that takes time away
                    from more important matters. One preferred solution of
                    executive teams is hiring virtual assistants. This post
                    explains how VAs can help businesses save on costs while
                    optimizing workflows.
                  </p>
                  <router-link
                    to="/blog/cost-savings-when-hiring-a-va"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/what-you-need-to-know-about-outsourcing-for-startups"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/outsourcestart.jpg"
                      alt="HouseBlending"
                      style="max-height: 375px; margin: auto; height: 375px"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/what-you-need-to-know-about-outsourcing-for-startups">
                      What You Need To Know About Outsourcing for Startups
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      14th July 2022
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/rob.jpg"
                        alt="Image"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Rob Astudillo
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Although entrepreneurship and running a startup are
                    exciting, it has their challenges. This article talks about
                    what you need to know about how outsourcing is a tactical
                    solution for startups.
                  </p>
                  <router-link
                    to="/blog/what-you-need-to-know-about-outsourcing-for-startups"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/blending-in-house-and-outsourced-sales-development"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/houseblending.jpg"
                      alt="HouseBlending"
                      style="max-height: 375px; margin: auto; max-width: 400px"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/blending-in-house-and-outsourced-sales-development">
                      How Blending In-House and Outsourced Sales Development
                      Achieves Business Growth
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      23rd June 2022
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/krystel.jpg"
                        alt="Image"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Krystel Moore
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    With the help of a hybrid sales development team through
                    outsourced partnerships, scaling and growing your business
                    is made possible. In this article, we explain how blending
                    in-house and outsourced sales teams can bring high
                    conversion rates.
                  </p>
                  <router-link
                    to="/blog/blending-in-house-and-outsourced-sales-development"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/the-good-time-to-outsource"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/outsourcewhen.jpg"
                      alt="OutsourceWhen"
                      style="max-height: 375px; margin: auto; height: 375px"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link to="/blog/the-good-time-to-outsource">
                      When is it a Good Time to Outsource?
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      15th June 2022
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/rob.jpg"
                        alt="Image"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Rob Astudillo
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Outsourcing the non-core functions of your business is an
                    excellent way to free up your time, lower overhead, and
                    scale your business, but when is it a good time to do so?
                    This article will help break it down for you.
                  </p>
                  <router-link
                    to="/blog/the-good-time-to-outsource"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/reasons-for-outsourcing-Philippines"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/outsourceph.jpg"
                      alt="OutsourcePH"
                      style="max-height: 375px; margin: auto; max-width: 400px"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link to="/blog/reasons-for-outsourcing-Philippines">
                      Why the Philippines is a Good Place to Outsource
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      2nd June 2022
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/krystel.jpg"
                        alt="Image"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Krystel Moore
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    The Philippines ranks 6th in the Top 50 Digital Nations list
                    and for a good reason. Filipino BPO workers have what it
                    takes to effectively provide high-quality customer service
                    with a combination of skills, talents, and work ethic.
                  </p>
                  <router-link
                    to="/blog/reasons-for-outsourcing-Philippines"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/virtual-assistants-and-return-on-investment"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/varoi.jpg"
                      alt="VAROI"
                      style="max-height: 375px; margin: auto; height: 375px"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/virtual-assistants-and-return-on-investment">
                      Virtual Assistants and Return on Investment (ROI): What
                      You Need To Know
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      18th May 2022
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/krystel.jpg"
                        alt="Image"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Krystel Moore
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    This article explores how hiring an experienced VA can be an
                    excellent investment for your business. VAs can save you
                    time and help you grow your business.
                  </p>
                  <router-link
                    to="/blog/virtual-assistants-and-return-on-investment"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/property-management-virtual-assistant-benefits"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/PropertyVA.jpg"
                      alt="PropertyVA"
                      style="max-height: 375px; width: 65%; margin: auto"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/property-management-virtual-assistant-benefits">
                      7 Benefits of Hiring a Property Management Virtual
                      Assistant
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      4th May 2022
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/krystel.jpg"
                        alt="Image"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Krystel Moore
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Property managers are always looking for ways to reduce
                    costs and increase earning potential. Hiring an experienced
                    virtual assistant can help accomplish both. While property
                    management VAs work remotely, they are 100% dedicated to
                    your business.
                  </p>
                  <p>
                    This post looks into the key benefits of hiring a property
                    management VA.
                  </p>
                  <router-link
                    to="/blog/property-management-virtual-assistant-benefits"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/customer-service-vs-customer-success"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/servicevssuccess.jpg"
                      alt="KeySkills"
                      style="max-height: 375px" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/customer-service-vs-customer-success">
                      Customer Service vs. Customer Success: What's the
                      Difference?
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      1st April 2022
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/rob.jpg"
                        alt="Image"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Rob Astudillo
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Customer success and customer service are among the hottest
                    buzzwords these days. These days, companies invest more in
                    creating a solid customer-centered culture to boost a higher
                    stock price. But what are the differences between customer
                    service and customer success? Read to find out.
                  </p>
                  <router-link
                    to="/blog/customer-service-vs-customer-success"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/tasks-to-delegate-to-property-management-virtual-assistant"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/20tasks.jpg"
                      alt="20Tasks"
                      style="max-height: 375px"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/tasks-to-delegate-to-property-management-virtual-assistant">
                      20 Tasks to Delegate to Your Property Management Virtual
                      Assistant
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      1st April 2022
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/rob.jpg"
                        alt="Image"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Rob Astudillo
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Each virtual assistant has unique skills and talents to
                    offer realtors so it’s important to know what you need to
                    delegate to make the partnership work. Here’s a list of 20
                    tasks you can delegate to your property management virtual
                    assistant.
                  </p>
                  <router-link
                    to="/blog/tasks-to-delegate-to-property-management-virtual-assistant"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/why-outsource-property-management-services"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/pms.jpg"
                      alt="PMS"
                      style="max-height: 375px"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/why-outsource-property-management-services">
                      Why It's a Smart Move to Outsource Property Management
                      Services
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      23rd March 2022
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/rob.jpg"
                        alt="Image"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Rob Astudillo
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Owning real estate is a good investment. But managing
                    properties isn't easy because each property has different
                    needs and demands. Even if there’s just one property to
                    manage, taking on general management tasks could still be
                    challenging. For situations like these, outsourcing property
                    management makes sense.
                  </p>
                  <router-link
                    to="/blog/why-outsource-property-management-services"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/skills-business-process-outsourcing-companies-need"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/keyskills.jpg"
                      alt="KeySkills"
                      style="max-height: 375px"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/skills-business-process-outsourcing-companies-need">
                      Key Skills Outsourcing Companies Need
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      11th March 2022
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/cole.jpg"
                        alt="Image"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Cole Abat
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Working in a business process outsourcing (BPO) company
                    takes more than communication and computer skills. In this
                    post, we put together a list of key skills outsourcing
                    companies need to make a lasting impact on the partner,
                    company, and business community.
                  </p>
                  <router-link
                    to="/blog/skills-business-process-outsourcing-companies-need"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/how-real-estate-vas-grow-your-business"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/realestate.jpg"
                      alt="Real Estate"
                      style="max-height: 375px"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/how-real-estate-vas-grow-your-business">
                      How Real Estate Virtual Assistants Grow Your Business
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      10th March 2022
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/rob.jpg"
                        alt="Image"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Rob Astudillo
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Real estate agents lead busy lives but still have to stay on
                    top of their game to survive in the cutthroat business. As
                    their partners increase, so do their daily tasks. This is
                    where real estate virtual assistants can come in handy. Find
                    out how real estate virtual assistants can grow your
                    business for you.
                  </p>
                  <router-link
                    to="/blog/how-real-estate-vas-grow-your-business"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/what-is-customer-centric-approach"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/customer.jpg"
                      alt="CCA"
                      style="max-height: 375px"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link to="/blog/what-is-customer-centric-approach">
                      What is a Customer-Centric Approach?
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      15th September 2021
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/rob.jpg"
                        alt="Image"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Rob Astudillo
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Constantly improving the customer experience should be
                    included in your equation for business success. The key is
                    to adopt the customer-centric approach in your business
                    processes. Read more and learn how it works.
                  </p>
                  <router-link
                    to="/blog/what-is-customer-centric-approach"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/reasons-why-offshore-outsourcing-is-a-smart-move"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/offshore.jpg"
                      alt="Offshore"
                      style="max-height: 375px"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/reasons-why-offshore-outsourcing-is-a-smart-move">
                      8 Reasons Why Offshore Outsourcing is a Smart Move
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      13th September 2021
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/cole.jpg"
                        alt="Image"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Cole Abat
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    Capital and manpower are higher commodities in a difficult
                    business environment, which is why companies need as many
                    talented people as possible to focus on what matters in the
                    business. This is where offshore outsourcing becomes an
                    effective strategy. Read more to find out why offshoring can
                    be the smartest move for your business.
                  </p>
                  <router-link
                    to="/blog/reasons-why-offshore-outsourcing-is-a-smart-move"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>

            <div class="entry col-sm-6 col-12">
              <div class="grid-inner">
                <div class="entry-image">
                  <router-link
                    to="/blog/essential-guide-to-business-process-outsourcing"
                    data-lightbox="image">
                    <img
                      src="/assets/images/blog/bpo.jpg"
                      alt="BPO"
                      style="max-height: 375px"
                      class="nostyle" />
                  </router-link>
                </div>
                <div class="entry-title">
                  <h2>
                    <router-link
                      to="/blog/essential-guide-to-business-process-outsourcing">
                      The Essential Guide to Business Process Outsourcing
                    </router-link>
                  </h2>
                </div>
                <div class="entry-meta">
                  <ul>
                    <li>
                      <i class="icon-calendar3"></i>
                      9th September 2021
                    </li>
                    <li>
                      <img
                        src="/assets/images/author/rob.jpg"
                        alt="Image"
                        class="rounded-circle"
                        style="width: 64px; height: 64px; margin-right: 15px" />
                      Rob Astudillo
                    </li>
                  </ul>
                </div>
                <div class="entry-content">
                  <p>
                    The BPO industry is a growing sector in today’s connected
                    world, and for good reasons. It plays a vital role in global
                    economies because it involves smart and innovative
                    processes. Find out more about the essentials about BPO to
                    take your business from good to great.
                  </p>
                  <router-link
                    to="/blog/essential-guide-to-business-process-outsourcing"
                    class="more-link">
                    Read More
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <!-- #posts end -->

          <!-- Pager
					============================================= -->
          <!-- <div class="d-flex justify-content-between mt-5">
						<a href="#" class="btn btn-outline-secondary">&larr; Older</a>
						<a href="#" class="btn btn-outline-dark">Newer &rarr;</a>
					</div> -->
          <!-- .pager end -->
        </div>
      </div>
    </section>
    <!-- #content end -->
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    mounted() {
      window.scrollTo(0, 0)
    },
  }
</script>

<style scoped>
  @media (min-device-width: 200px) and (max-device-width: 736px) {
    .nostyle {
      max-height: 100% !important;
      max-width: 100% !important;
      height: auto !important;
      width: auto !important;
    }
  }
</style>
